var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{key:`getStartedPage+${_vm.CurrentSelectedPage.id}`,staticClass:"row mt-5 w-85 big-div",staticStyle:{"position":"relative","z-index":"100"},style:(_vm.getPageDesign() +
      `zoom:${this.pageZoomValue ? this.pageZoomValue : 90}%;`)},[(_vm.getImageAlign == 'no' && _vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView) !== '')?_c('div',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView),staticClass:"bg",style:(_vm.getbackgroundImage(
          _vm.IsImageAdded,
          _vm.pageDesign.imageBlock,
          _vm.getImageAlign,
          _vm.isDesktopView
        ) + _vm.getBgDivStyles())}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLeftImage),expression:"showLeftImage"}],staticClass:"col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0",class:{
        'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
        'image-division': _vm.isDesktopView,
      },style:(_vm.calculateImageWidth(
          _vm.pageDesign.imageBlock,
          _vm.isDesktopView,
          _vm.IsImageVariableWidthActive
        ))},[_c('div',{class:_vm.isDesktopView ? 'image-division' : null,style:(_vm.getImageOpacity(_vm.pageDesign.imageBlock.style)),on:{"click":_vm.activeImageCustomize}},[_c('div',{style:(_vm.height100(_vm.isDesktopView))},[_c('LazyImage',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView),class:[
              `pic-${_vm.getSelectedPage.order}`,
              _vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView) ==
              '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ],style:(_vm.getUniversalImageStyle(
                _vm.isDesktopView,
                _vm.pageDesign.imageBlock.style,
                _vm.IsImageVariableWidthActive,
                _vm.pageDesign.imageBlock
              ) + _vm.getImageRotation(_vm.pageDesign.imageBlock.style)),attrs:{"src":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView)),"alt":""}})],1)]),_c('button',{staticClass:"hide close-icon",attrs:{"id":"clear"},on:{"click":_vm.deleteImage}},[_c('svg',{attrs:{"width":"32","height":"32","viewBox":"0 0 32 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z","fill":"black","fill-opacity":"0.5"}}),_c('path',{attrs:{"d":"M10.2857 23.2592C10.2857 24.4 11.1428 25.3333 12.1905 25.3333H19.8095C20.8571 25.3333 21.7143 24.4 21.7143 23.2592V10.8148H10.2857V23.2592ZM12.1905 12.8888H19.8095V23.2592H12.1905V12.8888ZM19.3333 7.70366L18.3809 6.66663H13.619L12.6666 7.70366H9.33331V9.77774H22.6666V7.70366H19.3333Z","fill":"white"}})])])]),_c('div',{staticClass:"",class:!_vm.IsImageAdded ? 'col-12' : _vm.imageAddedLayout,style:(_vm.getMainPaddingCss(_vm.pageDesign.pageStyles, _vm.isDesktopView) +
        _vm.getScrollCSS(
          _vm.pageDesign.pageStyles,
          _vm.isDesktopView,
          _vm.pageDesign.imageBlock.style
        ) +
        _vm.calculateContentWidth(
          _vm.pageDesign.imageBlock,
          _vm.isDesktopView,
          _vm.IsImageVariableWidthActive,
          _vm.pageDesign.pageStyles
        ))},[_c('div',[_c('draggable',_vm._b({staticClass:"dragArea list-group",staticStyle:{"cursor":"move"},attrs:{"group":"actions","chosen-class":"chosen","drag-class":"drag"},on:{"start":function($event){_vm.dragging = true},"end":function($event){_vm.dragging = false},"change":_vm.handleDraggableElementDrop},model:{value:(_vm.pageDesignBlocks),callback:function ($$v) {_vm.pageDesignBlocks=$$v},expression:"pageDesignBlocks"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.pageDesignBlocks),function(pro,ind){return _c('div',{key:'preview' + ind,class:_vm.dragging ? 'dragElementBorder' : null,attrs:{"draggable":"false"},on:{"click":function($event){return _vm.changeCustomize(ind)}}},[_c('div',{staticClass:"content designBlockElement",staticStyle:{"position":"relative"},style:(_vm.getHeightWidth(pro.style) + _vm.getDivBorderCss(pro)),attrs:{"id":`${pro.type}-${ind}`}},[(pro.customizeStatus)?_c('EditorAction',{attrs:{"deleteCondition":_vm.getDeleteButtonCondition(pro),"currElementIndex":ind,"currElementType":pro.type}}):_vm._e(),(pro.type == 'text')?_c('div',{style:(_vm.getColorCss(pro.style) +
                  _vm.getPaddingCss(pro.style) +
                  _vm.getMarginCss(pro.style, _vm.isDesktopView) +
                  _vm.getColorWithOpacity(pro.style) +
                  _vm.getTextRotate(pro.style) +
                  _vm.getLineHeight(pro.style) +
                  _vm.getTextAlign(pro.style) +
                  _vm.getFontStyle(pro.style, _vm.isDesktopView)),domProps:{"innerHTML":_vm._s(_vm.getEditorPersonalisedValueStyle(pro.content))}}):_vm._e(),(pro.type == 'timer')?[_c('Timer',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView,"isEditor":"true"}})]:_vm._e(),(pro.type == 'button')?_c('div',{style:('display:flex;justify-content:' + pro.style.position + ';')},[_c('button',{style:(_vm.getButtonCss(pro.style, _vm.isDesktopView))},[_c('span',{style:({
                      opacity: `${pro.style.textOpacity}%`,
                      fontSize: `${pro.style.fontSize}px`,
                    })},[_vm._v(" "+_vm._s(pro.text)+" ")]),_vm._v(" "+_vm._s(pro.style.alignItems)+" ")])]):_vm._e(),(pro.type == 'tooltip')?_c('div',{style:('display:flex;justify-content:' + pro.style.position + ';')},[_c('button',{style:(_vm.getButtonCss(pro.style, _vm.isDesktopView) +
                    _vm.getCircularBtn(pro.isBtnRounded)),on:{"mouseenter":function($event){pro.isTooltipVisible = true},"mouseleave":function($event){pro.isTooltipVisible = false}}},[(pro.useIcon)?_c('span',[_c('BIcon',{attrs:{"icon":pro.selectedIcon}})],1):_c('span',{style:({
                      opacity: `${pro.style.textOpacity}%`,
                      fontSize: `${pro.style.fontSize}px`,
                    })},[_vm._v(" "+_vm._s(pro.text)+" ")])]),(pro.isTooltipVisible)?_c('ToolTipComp',{attrs:{"tooltipData":pro,"isDesktopView":_vm.isDesktopView}}):_vm._e()],1):_vm._e(),(pro.type == 'html')?_c('div',{style:(_vm.getMarginCss(pro.style, _vm.isDesktopView) +
                  _vm.getColorWithOpacity(pro.style) +
                  _vm.getTextRotate(pro.style) +
                  _vm.getTextAlign(pro.style))},[_c('HtmlElement',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView}})],1):_vm._e(),(pro.type == 'imageComp')?_c('div',[_c('InlineImageComp',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView}})],1):_vm._e()],2)])}),0)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRightImage),expression:"showRightImage"}],staticClass:"col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0",class:{
        'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
        'image-division': _vm.isDesktopView,
      },style:(_vm.calculateImageWidth(
          _vm.pageDesign.imageBlock,
          _vm.isDesktopView,
          _vm.IsImageVariableWidthActive
        ))},[_c('div',{class:_vm.isDesktopView ? 'image-division' : '',on:{"click":_vm.activeImageCustomize}},[_c('div',{style:(_vm.height100(_vm.isDesktopView))},[_c('LazyImage',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView),class:[
              `pic-${_vm.getSelectedPage.order}`,
              _vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView) ==
              '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ],style:(_vm.getUniversalImageStyle(
                _vm.isDesktopView,
                _vm.pageDesign.imageBlock.style,
                _vm.IsImageVariableWidthActive,
                _vm.pageDesign.imageBlock
              ) +
              _vm.getImageOpacity(_vm.pageDesign.imageBlock.style) +
              _vm.getImageRotation(_vm.pageDesign.imageBlock.style)),attrs:{"src":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView)),"alt":""}})],1)]),_c('button',{staticClass:"hide close-icon",attrs:{"id":"clear"},on:{"click":_vm.deleteImage}},[_c('svg',{attrs:{"width":"32","height":"32","viewBox":"0 0 32 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z","fill":"black","fill-opacity":"0.5"}}),_c('path',{attrs:{"d":"M10.2857 23.2592C10.2857 24.4 11.1428 25.3333 12.1905 25.3333H19.8095C20.8571 25.3333 21.7143 24.4 21.7143 23.2592V10.8148H10.2857V23.2592ZM12.1905 12.8888H19.8095V23.2592H12.1905V12.8888ZM19.3333 7.70366L18.3809 6.66663H13.619L12.6666 7.70366H9.33331V9.77774H22.6666V7.70366H19.3333Z","fill":"white"}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }